/**
 * App.js
 *
 * @author Dima Dodonov <dimadodonov@icloud.com>
 */

//Global Vars
const $window = $(window);
const $document = $(document);
const $html = $('html');
const $wrapper = $('.wrapper');
const $header = $('.header');
const $main = $('.main');
const $overlay = $('.js-overlay');

const App = {
    init() {
        // console.log('Site init');
        this.header();
        this.slider();
        this.fancybox();
        this.youtube();
        this.Components.init();
    },

    header() {
        if ($(window).width() >= 480) {
            $(window).on('scroll', function () {
                var sT = $(this).scrollTop();

                if (sT > 150) {
                    $('.header-stiky').addClass('on');
                } else {
                    $('.header-stiky').removeClass('on');
                }
            });
        }

        $('.jsSearchInit').on('click', function () {
            $(this).removeClass('show').addClass('hide');
            $('.jsSearchClose').removeClass('hide').addClass('show');
            $('.header-search__wrap').show();
            $('.nav-mob-search__wrap').show();
        });

        $('.jsSearchClose').on('click', function () {
            $(this).removeClass('show').addClass('hide');
            $('.jsSearchInit').removeClass('hide').addClass('show');
            $('.header-search__wrap').hide();
            $('.nav-mob-search__wrap').hide();
        });

        //Toggle-Mnu
        $('.toggle-mnu').click(function () {
            $(this).toggleClass('on');
            $('.nav-mob').toggleClass('on');
            $('body').toggleClass('is-fixed');
            return false;
        });

        $(document).mouseup(function (e) {
            // событие клика по веб-документу
            var div = $('.nav-mob'); // тут указываем ID элемента
            if (
                !div.is(e.target) && // если клик был не по нашему блоку
                div.has(e.target).length === 0
            ) {
                // и не по его дочерним элементам
                $('.nav-mob').removeClass('on');
                $('.toggle-mnu').removeClass('on');
                $('body').removeClass('is-fixed');
            }
        });

        // Menu click
        $('.dropdown > a').click('click', function () {
            $(this).toggleClass('on');
            $(this).parent('li').toggleClass('on');
            return false;
        });
    },

    slider() {
        var swiper = new Swiper('.swiper-single', {
            pagination: {
                autoHeight: true,
                el: '.swiper-pagination',
                dynamicBullets: true,
            },
        });
    },
    fancybox() {
        $('[data-fancybox]').fancybox({
            clickOutside: 'close',
            buttons: [
                //"zoom",
                //"share",
                //"slideShow",
                //"fullScreen",
                //"download",
                //"thumbs",
                'close',
            ],
            protect: true, // РїРѕР»СЊР·РѕРІР°С‚РµР»СЊ РЅРµ РјРѕР¶РµС‚ СЃРѕС…СЂР°РЅРёС‚СЊ РёР·РѕР±СЂР°Р¶РµРЅРёРµ
            // toolbar  : false // СѓР±СЂР°Р»Рё РїР°РЅРµР»СЊ РёРЅСЃС‚СЂСѓРјРµРЅС‚РѕРІ
            mobile: {
                clickContent: 'close',
                clickSlide: 'close',
            },
        });
    },
    youtube() {
        $(function () {
            $('.single-content iframe').wrap(
                "<div class='iframe-flexible-container'></div>"
            );
        });
    },
};

App.define = function (namespace) {
    var parts = namespace.split('.'),
        parent = App,
        i;

    //Убрать начальный префикс если это являетсся глобальной переменной
    if (parts[0] == 'App') {
        parts = parts.slice(1);
    }

    //Если в глобальном объекте нет свойства - создать его.
    for (var i = 0; i < parts.length; i++) {
        if (typeof parent[parts[i]] == 'undefined') {
            parent[parts[i]] = {};
        }
        parent = parent[parts[i]];
    }
    return parent;
};

/**

 * Components.js

 *

 * @author Dima Dodonov <dimadodonov@icloud.com>

 */



App.define('Components');



App.Components = (function () {

    function _init() {

        console.log('Site Components Init');

        // _initInstaFeed();

        // App.Components.Input.init();

    }



    // function _initInstaFeed() {

    //     _initInstaFeed();

    // }



    return {

        init: _init,

        // instaFeed: _initInstaFeed

    };

})();



App.define('Components.Input');

App.Components.Input = (function () {

    function _init() {

        console.log('Site Components Input Init');

        _initMask();

    }



    function _initMask() {

        //Masked inputmask https://github.com/RobinHerbots/Inputmask

        let phoneMask = new Inputmask('+7 (999) 999-99-99');

        let inputPhone = document.querySelector('.js-phone-mask');

        if (inputPhone) {

            phoneMask.mask(inputPhone);

        }

    }



    return {

        init: _init,

        initMask: _initMask,

    };

})();



// Убрать сплешь после знака равно

$(function () {

    if ($(window).width() >= 480) {

        const simpleBar = new SimpleBar($('.JsSidebarWrap')[0]);

        simpleBar.getScrollElement().addEventListener('scroll', function () {

            const sidebarWrap = $(this).height();

            const sidebarWrapScroll = $(this).scrollTop();

            const sidebarItem = $('.JsSidebarItem').offset().top;



            // console.log(sidebarItem.toFixed(0) + "+" + sidebarWrapScroll);

            var data = {

                action: 'loadmore',

                query: true_posts,

                page: current_page,

            };



            if (

                sidebarItem.toFixed(0) < sidebarWrapScroll &&

                !$('body').hasClass('loading')

            ) {

                $.ajax({

                    url: ajaxurl,

                    data: data,

                    type: 'POST',

                    beforeSend: function (xhr) {

                        $('body').addClass('loading');

                    },

                    success: function (data) {

                        if (data) {

                            $('#true_loadmore').before(data);

                            $('body').removeClass('loading');

                            current_page++;

                        }

                    },

                });

            }

        });

    }

});



jQuery(function ($) {

    // use jQuery code inside this to avoid "$ is not defined" error

    $(document).on('click', '.misha_loadmore', function () {

        var button = $(this),

            $tax = $(this).attr('post-type'),

            data = {

                action: 'loader',

                query: misha_loadmore_params.posts, // that's how we get params from wp_localize_script() function

                page: misha_loadmore_params.current_page,

            };



        $.ajax({

            // you can also use $.post here

            url: misha_loadmore_params.ajaxurl, // AJAX handler

            data: data,

            type: 'POST',

            beforeSend: function (xhr) {

                button.text('Загружаю...'); // change the button text, you can also add a preloader image

            },

            success: function (data) {

                if (data) {

                    button.text('Загрузить еще'); // insert new posts

                    $('.archive-loader').before(data); // insert new posts

                    misha_loadmore_params.current_page++;



                    if (

                        misha_loadmore_params.current_page ==

                        misha_loadmore_params.max_page

                    )

                        button.remove(); // if last page, remove the button



                    // you can also fire the "post-load" event here if you use a plugin that requires it

                    // $( document.body ).trigger( 'post-load' );

                } else {

                    button.remove(); // if no data, remove the button as well

                }

            },

        });

    });



    $(document).on('click', '.post-load', function () {

        var loadmore = $(this),

            current_page = loadmore.attr('data-current'),

            current_category = loadmore.attr('data-category');

        loadmore.text('Загружаю...'); // изменяем текст кнопки, вы также можете добавить прелоадер

        var data = {

            action: 'postload',

            query: loadmore.data('true-posts'),

            page: current_page,

            cat: current_category,

        };

        $.ajax({

            url: ajaxurl, // обработчик

            data: data, // данные

            type: 'POST', // тип запроса

            success: function (data) {

                if (data) {

                    loadmore.text('Загрузить ещё').before(data); // вставляем новые посты

                    current_page++; // увеличиваем номер страницы на единицу

                    loadmore.attr('data-current', current_page);

                    if (current_page == loadmore.data('max-pages'))

                        loadmore.remove(); // если последняя страница, удаляем кнопку

                } else {

                    loadmore.remove(); // если мы дошли до последней страницы постов, скроем кнопку

                }

            },

        });

    });

});



jQuery.extend(jQuery.fn, {

    /*

     * функция проверки, что длина поля не меньше 3х символов

     */

    checka: function () {

        if (jQuery(this).val().length < 3) {

            jQuery(this).addClass('error');

            return false;

        } else {

            jQuery(this).removeClass('error');

            return true;

        }

    },

    /*

     * функция проверки правильности введенного email

     */

    checke: function () {

        var emailReg = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;

        var emailaddressVal = jQuery(this).val();

        if (!emailReg.test(emailaddressVal) || emailaddressVal == '') {

            jQuery(this).addClass('error');

            return false;

        } else {

            jQuery(this).removeClass('error');

            return true;

        }

    },

});



jQuery(function ($) {

    $('#commentform').submit(function () {

        // может такое случиться, что пользователь залогинен - нужно это проверить, иначе валидация не пройдет

        if ($('#author').length) var author = $('#author').checka();

        if ($('#email').length) var email = $('#email').checke();

        var comment = $('#comment').checka();

        // небольшое условие для того, чтобы исключить двойные нажатия на кнопку отправки

        // в это условие также входит валидация полей

        if (

            !$('#submit').hasClass('loadingform') &&

            !$('#author').hasClass('error') &&

            !$('#email').hasClass('error') &&

            !$('#comment').hasClass('error')

        ) {

            $.ajax({

                type: 'POST',

                url: 'http://' + location.host + '/wp-admin/admin-ajax.php',

                data: $(this).serialize() + '&action=ajaxcomments',

                beforeSend: function (xhr) {

                    // действие при отправке формы, сразу после нажатия на кнопку #submit

                    $('#submit').addClass('loadingform').val('Загрузка');

                },

                error: function (request, status, error) {

                    if (status == 500) {

                        alert('Ошибка при добавлении комментария');

                    } else if (status == 'timeout') {

                        alert('Ошибка: Сервер не отвечает, попробуй ещё.');

                    } else {

                        // ворпдрессовские ошибочки, не уверен, что это самый оптимальный вариант

                        // если знаете способ получше - поделитесь

                        var errormsg = request.responseText;

                        var string1 = errormsg.split('<p>');

                        var string2 = string1[1].split('</p>');

                        alert(string2[0]);

                    }

                },

                success: function (newComment) {

                    // Если уже есть какие-то комментарии

                    if ($('.commentlist').length > 0) {

                        // Если текущий комментарий является ответом

                        if ($('#respond').parent().hasClass('comment')) {

                            // Если уже есть какие-то ответы

                            if (

                                $('#respond').parent().children('.children')

                                    .length

                            ) {

                                $('#respond')

                                    .parent()

                                    .children('.children')

                                    .append(newComment);

                            } else {

                                // Если нет, то добавляем  <ul class="children">

                                newComment =

                                    '<ul class="children">' +

                                    newComment +

                                    '</ul>';

                                $('#respond').parent().append(newComment);

                            }

                            // закрываем форму ответа

                            $('#cancel-comment-reply-link').trigger('click');

                        } else {

                            // обычный коммент

                            $('.commentlist').append(newComment);

                        }

                    } else {

                        // если комментов пока ещё нет, тогда

                        newComment =

                            '<ul class="commentlist">' + newComment + '</ol>';

                        $('#respond').before($(newComment));

                    }

                    // очищаем поле textarea

                    $('#comment').val('');

                },

                complete: function () {

                    // действие, после того, как комментарий был добавлен

                    $('#submit').removeClass('loadingform').val('Отправить');

                },

            });

        }

        return false;

    });

});





$(function () {
    App.init();
});
